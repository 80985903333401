import { Box } from "@chakra-ui/react";
import BackgroundImage from "gatsby-background-image";
import React from "react";
import { useAnnouncements } from "../../../lib/features/announcement/hooks/useAnnouncements";
import { convertImage } from "../../../lib/features/image/utils/imageUtils";
import { AnnouncementsSectionProps } from "../../../lib/features/page/content-sections.d";
import { Container } from "../../Shared/Container/Container";
import { Overlay } from "../../Shared/Overlay/Overlay";
import { Text } from "../Text/Text";
import { AnnouncementsGrid } from "./AnnouncementsGrid";

export const AnnouncementsSection = (props: AnnouncementsSectionProps) => {
  const { heading, backgroundImage, useMostRecent, ...restOfProps } = props;

  let bgImage = convertImage(backgroundImage);

  let announcements = useAnnouncements();
  if (!useMostRecent) {
    announcements = props.announcements.slice(0, 4);
  }

  return (
    <Box {...restOfProps} my={-2}>
      {bgImage ? (
        <BackgroundImage {...bgImage} preserveStackingContext>
          <Overlay color="white" zIndex={0} opacity={90} />
          <Container py={backgroundImage ? 16 : 0} position="relative">
            <Text {...heading} />
            <AnnouncementsGrid announcements={announcements} />
          </Container>
        </BackgroundImage>
      ) : (
        <Container py={backgroundImage ? 14 : 0} position="relative">
          <Text {...heading} />
          <AnnouncementsGrid announcements={announcements} />
        </Container>
      )}
    </Box>
  );
};
